.mainSection {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;    
    padding: 55px;
    background-color: white;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 35px;
    border-radius: var(--border-radius-out);
    box-shadow: var(--shadow-50);
    margin-bottom: 35px;
    flex: 1 1 1000px;
}

.container {
    display: flex;
    padding-right: 35px;
}

.leftPanel {
    flex: 1 1 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightPanel {
    flex: 1 1 450px;
}


