.grid {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
    gap: 15px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.list article .studentNameSection {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: start;
    text-align: left;
    margin-right: 15px;
    flex: 0 0 150px;
}

.list article {
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
}

.list article p {
    display: flex;
    gap: 15;
}

/* .list article p span {
    flex: 1 1 200px;
} */

.list article img {
    width: 57px;
    height: 57px;
}

.cardLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -10px;
}

.reward {
    fill: var(--color-reward-50);
}


.buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.delete {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 38px;
    height: 38px;
    min-width: 38px;
    fill: var(--color-red-50);
    background-color: var(--color-red-5);
    border: 5px solid white;
}

/* .delete:hover {
    fill: white;
    background-color: var(--color-red-50);
    border: 5px solid white;
} */

div:hover > .options {
    opacity: 1;
}

.options {
    position: absolute;
    right: 5px;
    top: 8px;
    width: 25px;
    height: 25px;
    fill: var(--color-gray-25);
    z-index: 999;
    border: none;
    opacity: 0; 
    transition: all .5s;
    
    
}

.options svg {
    width: 18px;
    height: 18px;
}

.options:hover {
    background-color: white;
    fill: var(--color-gray-50);
    border: none;
    
}

.removed {
    display: none;
}
/* 
.calm {
 fill: var(--color-emotion-calm);
}

.stressed {
    fill: var(--color-emotion-stressed);
}

.angry {
    fill: var(--color-emotion-angry);
}

.nocheck {
    fill: var(--color-gray-25);
} */

.studentCheckBox {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.noResults {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 200px;
    flex-direction: column;
}

.noResults p {
    text-align: center;

}