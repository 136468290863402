.small, .small svg {
    width: 23px;
    height: 23px;
}

.medium, .medium svg {
    width: 37px;
    height: 37px;
}

.large, .large svg {
    width: 50px;
    height: 50px;
}

.selected .checkSparkle{
    fill:none;
    stroke:red;
    stroke-dasharray: 10;
    stroke-width: 5px;
    animation: dash 1s linear forwards;
    /* display: none; */
}

@keyframes dash {
    from {
        stroke-dashoffset: 0;
      }
      to {
        stroke-dashoffset: 18;
      }
  }

.over {   
    position: absolute;
    pointer-events: none;
    left: 5px;
}