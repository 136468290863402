.header {
    background-color: var(--color-blue-5);
    height: 65px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-out) var(--border-radius-out) 0 0;
    font-weight: bold;
    font-size: var(--font-size-title-1);
    color: var(--color-gray-75);
    fill: white;
}

.header.reward {
    background-color: var(--color-reward-5);
    border-bottom: 3px solid var(--color-reward-25);
    fill: var(--color-reward-75);
}

.header.store {
    background-color: var(--color-store-5);
    border-bottom: 3px solid var(--color-store-25);
    fill: var(--color-store-75);
}

.header.sel {
    background-color: var(--color-sel-5);
    border-bottom: 3px solid var(--color-sel-25);
    fill: var(--color-sel-75);
}