.selectionType {
    flex: 0 1 270px;
    padding: 30px;
    padding-right: 15px;
}

@media screen and (max-width: 800px) {
    .selectionType {
        display: none;
    }

    header .selectionType {
        display: block;
    }
}

@media screen and (max-width: 1400px) {
    .selectionType {
        flex: 0 1 50px;
    }
  }

/* Labels */

.containerLabel {
    display: flex;
    gap: 10px;
    align-items: center;
}

.selectionType h2 {
    color: var(--color-gray-75);
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 6px;
}

.typeCreate {
    background-color: var(--color-blue-15);
    border: none;
    fill: var(--color-blue-2);
    width: 19px;
    height: 19px;
    margin-bottom: -2px;
}

.typeCreate svg {
    width: 15px;
}

/* Buttons */
.selectionType a {
    display: flex;
    gap: 10px;
    align-items: center;
    /* padding: 5px 10px; */
    margin: 10px 0;
    border-radius: 7px;
    text-decoration: none;
    color: black;
    transition: all .5s;
    opacity: .3;
}

.selectionType a.active {
    opacity: 1;
}

.selectionType a p {
    line-height: 1.1;
}

.selectionType a:hover {
    opacity: 1;
}

.selectionType img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 4px solid white;
    box-shadow: var(--shadow-50);
    transition: all .7s;
}

.selectionType a:hover img {
    box-shadow: var(--shadow-75);
}

@media screen and (max-width: 1400px) {
    .selectionType a p {
        display: none;
    }

    header .selectionType a p {
        display: block;
    }
  }

/* Drop  */

.abledrop {
    outline: 4px dotted var(--color-blue-15);
    border-radius: var(--border-radius-75);
    transition: opacity .7s;
}

.abledrop a, .willdrop a {
    opacity: 1;
}

.willdrop {
    outline: 4px solid var(--color-blue-25);
    background-color: var(--color-blue-15);
    border-radius: var(--border-radius-75);
    transition: all .7s;
}