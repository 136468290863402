.container {
    background-color: white;
    padding: 15px 15px;
    border-radius: var(--border-radius-out);
    position: absolute;
    top: 20px;
    right: -20px;
    z-index: 1000;
    width: 180px;
    text-align: left;
    color: var(--color-gray-95);
    box-shadow: var(--shadow-50);
    display: none;
}

.wrapper {
    border: 1px solid red;
    position: absolute;
    top: 0;
    right: 0;
}

.show {
    display: block;
}

.container[open] {
    display: block;
}



.container p {
    line-height: 1.7;
}