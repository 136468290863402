.container {
    background-color: white;
    width: 600px;
    gap: 40px;
    z-index: 100000;
    border-radius: 25px;
    border: 0px;
    box-shadow: var(--shadow-100);
}

dialog.container::backdrop {
    background: #1540a3ad;
    backdrop-filter: blur(1px);
    
}

.header, .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
}

.footer {
    margin: 20px;
}

.center {
    justify-content: center;
}

.header h2 {
    margin: 0px;
}

.header button {
    background-color: transparent;
    outline: 0px;
    border: 0px;
    fill: var(--color-gray-35);
    cursor: pointer;
    border-radius: 100%;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header button:hover {
    fill: var(--color-gray-50);
    background-color: var(--color-gray-5);

}

.body {
    margin: 40px 20px;
}