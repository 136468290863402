

.body {
    min-height: 100px;
    padding: 20px;
    width: 100%;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
}



.selected input {
    background-color: white;
}

.sel .selected {
    background-color: var(--color-sel-5);
    /* fill: var(--color-sel-75); */
    color: var(--color-gray-75);
}

.selected.angry {
    fill: var(--color-emotion-angry)
}

.selected.calm {
    fill: var(--color-emotion-calm)
}

.selected.stressed {
    fill: var(--color-emotion-stressed)
}



