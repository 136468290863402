.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 25px;

}

.center {
    justify-content: center;
}

.center .middle {
    flex: 0 1 300px;
}

.left, .right, .middle {
    display: flex;
    gap: 7px;
    position: relative;
}


.container button {
    height: 40px;
    min-width: 0px;
    padding: 0 20px;
}

.container input {
    height: 40px;
    margin: 0px;
    background-color: white;
    border: 1px solid var(--color-gray-35);
}

.middle {
    fill: var(--color-blue-50);
    position: relative;
}

.middle span {
    position: absolute;
    right: 12px;
    top: 6px;

}



