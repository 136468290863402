.container {
    position: relative;
}

a.active, a.active .count, a:hover .count {
    opacity: 1;
}

.label {
    padding-right: 23px;
}

.count {
    font-size: var(--font-size-details);
    color: var(--color-blue-15);
    font-weight: bold;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 5px;
    padding: 0px;
    /* background-color: var(--color-blue-15); */
    /* border: 2px solid var(--color-blue-10); */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    opacity: 0;
    transition: opacity var(--time-50);
}

@media screen and (max-width: 1400px) {
    .container a .count {
        display: flex;
    }
  }

/* Drop  */

.abledrop {
    outline: 4px dotted var(--color-blue-15);
    border-radius: var(--border-radius-75);
    transition: opacity var(--time-50);
}

.abledrop a, .willdrop a, .abledrop .count, .willdrop .count {
    opacity: 1;
}

.willdrop .count {
    color: var(--color-blue-50);
}

.willdrop {
    outline: 4px solid var(--color-blue-25);
    background-color: var(--color-blue-15);
    border-radius: var(--border-radius-75);
    transition: all var(--time-50);
}

/* Messages */
.message {
    background-color: var(--color-blue-50);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    fill: white;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-75);
    opacity: 0;
    pointer-events: none;
    z-index: var(--z-index-message);
}

.message p strong {
    color: white;
    margin-left: 5px; 
}

@media screen and (max-width: 1400px) {
    .message p strong {
        display: none;  
    }
}

.ingroup {
    animation-name: message;
    animation-duration: 3s;
}

@keyframes message {
    0%   {opacity: 0;}
    25%  {opacity: 1;}
    75%  {opacity: 1;}
    100% {opacity: 0;}
  }