.container {
    display: flex;
    gap: 40px;
    margin-bottom: 20px;
}

.onethird {
    flex: 0 0 200px;
}

.onethird img {
    border-radius: 100%;
}

.twothirds {
    flex: 1 1 300px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
}

