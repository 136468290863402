.container {
    position: relative;
  }
  
  .card {
    background-color: white;
    border-radius: var(--border-radius-out);
    box-shadow: var(--shadow-50);
    /* min-height: 50px; */
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    cursor: pointer;
    padding: 0 5px;
    border: 6px solid white;
    transition: all .2s;
  }

  .card:before {
    content: " ";
    position: absolute;
    z-index: 1;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    border: 1px solid white;
    border-radius: var(--border-radius-out);
    pointer-events: none;
    transition: all .2s;
  }

  .card:hover {
    box-shadow: var(--shadow-75);
  }

  /* Using :has selector (will not work in Firefox yet 20221231) */

.card:has(input:checked) {
    box-shadow: var(--shadow-75);
    border-color: var(--color-store-25);
    
  }

  .card:has(input:checked):before {
    border-color: var(--color-store-50);
  }
  
  .card:has(input:checked):hover {
    box-shadow: var(--shadow-100);
  }

  /* Card Sections */
  .label {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 3px 0px 0px;
  }

  .cost {
    flex: 0 1 85px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    fill: var(--color-reward-75);
  }

  .cost p {
    margin-left: -2px;
  }