.background {
    width: 100vw;
    height: 100vh;
    background-color: var(--color-blue-50);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 50px;
}

body:has(.background), html:has(.background) {  background-color: var(--color-blue-50); }

.container {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 0 1 600px;
}

.title {
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    color: var(--color-blue-50);
    font-family: Cera, sans-serif;
}

.panel {
    margin: 20px;
    padding: 40px 40px 60px;
    background-color: white;
    border-radius: var(--border-radius-75);
    box-shadow: var(--shadow-50);
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    flex: 0 1 600px;
    margin-bottom: 100px;
}

.formPanelContainer {
    width: 100%;
    display: flex;
    max-width: 400px;
    flex-direction: column;
}

.formPanelContainer > form > div {
    flex: 1 0 auto !important;
}

.formPanelContainer label, .formPanelContainer p {
 margin-left: 5px;
}


.animatedWave {
    position:fixed;
    bottom: 0px;
    width: 100vw;
    opacity: .2;
  }
  
  .animatedWave path {
  
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-name: large-wave-move;
    animation-timing-function: linear;
    transition: linear;
    transition-timing-function: linear;
  }
  
  @keyframes large-wave-move {
    0% {transform: translateX(-100%)}
    to {transform: translateX(0)}
  }