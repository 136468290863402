.button {
  background-color: white;
  min-width: 125px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #9cb8f9;
  border-radius: 25px;
  color: var(--color-gray-75);
  margin-bottom: 20px;
  cursor: pointer;
  font-size: var(--font-size-body);
  font-weight: bold;
  margin-bottom: 0px;
  color: var(--color-blue-50);
  fill: var(--color-blue-50);
  text-decoration: none;
  position: relative;
}



.button:hover {
  background-color: var(--color-blue-50);
  fill: white;
  color: white;
  border: 1px solid var(--color-blue-50);
}

a:has(.button) {
  text-decoration: none;
}

/* Type */

.primary {
  background-color: var(--color-blue-50);
  color: white;
}

.primary:hover {
  background-color: white;
  color: var(--color-blue-50);
}

.text {
  background-color: transparent;
  font-weight: normal;
  text-decoration: underline;
  border: 0px;
  height: auto;
  font-size: var(--font-size-details);
}

.text:hover {
  text-decoration: none;
  background-color: transparent;
  height: auto;
  border: 0px;
  color: var(--color-blue-50);
}

.icon {
  width: 50px;
  height: 50px;
  min-width: auto;
  border-color: white;
  fill: var(--color-gray-25);
  
}

.icon:hover {
  background-color: var(--color-gray-25);
  fill: white;
  border-color: var(--color-gray-25);
}

/* Purpose */

.create {
  fill: var(--color-green-50);
}

.create:hover {
  background-color: var(--color-green-50);
  border-color: var(--color-green-50); 
}

/* Size */

.small {
  width: 25px;
  height: 25px;
}

/* State */

.button.reward {
  background-color: var(--color-reward-75);
  border: 1px solid var(--color-reward-75);
  color: white;
}

.button.reward:hover {
  background-color: var(--color-reward-100);
  border: 1px solid var(--color-reward-100);
}

.button.store {
  background-color: var(--color-store-75);
  border: 1px solid var(--color-store-75);
  color: white;
}

.button.sel {
  background-color: var(--color-sel-75);
  border: 1px solid var(--color-sel-75);
  color: white;
}

.button.loading {
  background-color: var(--color-gray-50);
  color: white;
  border: none;
}