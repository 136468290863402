.teacherProfile {
    border-radius: 100%;
    max-width: 100%;
    padding: 50px;
    
}

.teacherFeatures {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 50px;
    padding-top: 0px;
    padding-bottom: 50px;
    margin-top: -25px;
    fill: var(--color-gray-25);
}

/* Offers */

.listOffers {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.listOffers h2 {
    margin-bottom: -15px;
}

a.offerLink {
    text-decoration: none;
    padding: 10px;
    border-radius: var(--border-radius-75);
    transition: all var(--time-50);
 
}

a.offerLink:hover {
    background-color: white;
}

a.offerLink h3 {
    color: var(--color-gray-75);
    margin-bottom: 0px;
    margin-top: 0px;
}

.offerContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
}

.offerContainer img {
    border-radius: var(--border-radius-out);
    flex: 0 1 75px;
    max-width: 75px;
    max-height: 75px;
}

.offerContainer .offerContent {
    flex: 1 1 175px;
}




