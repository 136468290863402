.panel {
    background-color: white;
    border-radius: var(--border-radius-out);
    box-shadow: var(--shadow-50);
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    margin-bottom: 20px;
    /* position: sticky;
    top: 125px; */
}
.sticky {
  position: sticky;
  top: 125px;
}

