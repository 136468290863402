.profileImg {
    border-radius: 100%;
    width: 250px;
    height: 250px;
}

.imageContainer {
    width: 250px;
    height: 250px;
    border-radius: 100%;
    background-color: var(--color-gray-5);
}

.loading {
    width: 250px;
    height: 250px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.container input[type=file]::file-selector-button {
    margin: 0 auto;
}

