.card {
    background-color: white;
    border-radius: var(--border-radius-out);
    box-shadow: var(--shadow-50);
    /* margin: 20px; */
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}

.teacher {
display: flex;
flex-direction: column;
padding: 10px;
margin: 0px;
transition: all .2s;
cursor: pointer;
border-radius: var(--border-radius-student);
border: 6px solid white;
}

.teacher:hover {
    box-shadow: var(--shadow-75);
}

.teacher img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin-bottom: 7px;
    object-fit: cover;
    object-position: center;
    border: 1px solid var(--color-gray-15);
}   

.teacher.selected {
    border: 6px solid rgb(228, 228, 228);
    box-shadow: var(--shadow-75);
  }

  .teacher.selected:hover {
    box-shadow: var(--shadow-100);
}





 