.imagePreview {
    width: 100%;
    background-color: var(--color-gray-15);
    background-size: cover;
    background-position: center center;
    height: 200px;
    border-radius: var(--border-radius-75);
}

.inputFile {
    display: none;
}

.imageIcon {
    position: absolute;
    bottom: 10px;
    left: 15px;
    border-radius: 100%;
    cursor: pointer;
    fill: var(--color-gray-75);
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageIcon:hover {
    background-color: white;
    fill: var(--color-blue-50);
}

.label {
height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}