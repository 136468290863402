.buttonContainer {
    display: flex;
    justify-content: flex-end;
}

.container {
    font-size: 14px;
    position: relative;
}

.itemCheckBox {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.itemList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}

div:hover > .options {
    opacity: 1;
}

/* Options */
.options {
    position: absolute;
    right: 5px;
    top: 9px;
    width: 20px;
    height: 20px;
    fill: var(--color-gray-25);
    z-index: 999;
    border: none;
    opacity: 0; 
    transition: all .5s;
}

.options svg {
    width: 18px;
    height: 18px;
}

.options:hover {
    background-color: white;
    fill: var(--color-gray-50);
    border: none;
}



