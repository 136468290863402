.container {
    padding: 20px;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
}

.selected {
    background-color: var(--color-reward-15);
    fill: var(--color-reward-75);
    color: var(--color-gray-75);
}

.selected input {
    background-color: white;
}