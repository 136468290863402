.mainSection {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    flex: 1 1 1000px;
    padding: 60px;
    padding-right: 80px;
}

.cardGroup {
    flex: 1 1 550px;
}

.mainPanel {
    flex: 1 1 250px;
    top: 100;
    position: sticky;
}

.feedSection .mainPanel {
    flex: 0 1 300px;
}

@media screen and (max-width: 800px) {
    .mainSection {
        padding: 20px;
        padding-top: 40px;
        padding-right: 20px;
  }
}


