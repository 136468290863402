.container {
  position: relative;
}

.card {
    background-color: white;
    border-radius: var(--border-radius-out);
    box-shadow: var(--shadow-50);
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}

.student {
  display: flex;
  flex-direction: column;
  padding: 17px 10px;
  margin: 0px;
  transition: all .2s;
  cursor: pointer;
  border-radius: var(--border-radius-student);
  border: 6px solid white;
}

.student:before {
  content: " ";
  position: absolute;
  z-index: 1;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  border: 1px solid white;
  border-radius: var(--border-radius-student);
  pointer-events: none;
  transition: all .2s;
}

.student p strong {
  font-size: 14px;
}

/* Card Dragging */
article:hover > .move {
  opacity: 1;
}

.move {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  fill: var(--color-gray-10);
  z-index: 999;
  border: none;
  opacity: 0; 
  transition: all .5s;
}

.move svg {
  width: 18px;
  height: 18px;
}

.move:hover {
  background-color: white;
  fill: var(--color-gray-35);
  border: none;
}

.panel {
  position: sticky;
  top: 125px;
}

.student:hover {
    box-shadow: var(--shadow-75);
}

.student img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin-bottom: 7px;
    pointer-events: none;
}   

/* Using :has selector (will not work in Firefox yet 20221231) */

.student:has(input:checked) {
  box-shadow: var(--shadow-75);
}

.student:has(input:checked):hover {
  box-shadow: var(--shadow-100);
}

.student:has(input:checked).reward {
  border-color: var(--color-reward-25);
}

.student:has(input:checked).reward:before {
  border-color: var(--color-reward-50);
}

.student:has(input:checked).store {
  border-color: var(--color-store-25);
}

.student:has(input:checked).store:before {
  border-color: var(--color-store-50);
}

.student:has(input:checked).sel {
  border-color: var(--color-sel-25);
}

.student:has(input:checked).sel:before {
  border-color: var(--color-sel-50);
}


/* Reward Animation */
.iconCircle {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-reward-75);
  border: 3px solid white;
  fill: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  border-radius: 100%;
  opacity: 0;
  animation-name: reward;
  animation-duration: 2s;
  box-shadow: var(--shadow-15);
}

.iconCircle svg {
  margin-left: 0px;
  margin-top: -2px;
}

@keyframes reward {
  0%   {
          opacity: 0;
          top: 10px;
          box-shadow: var(--shadow-15);
          transform:translate(-50%, -50%) rotate(0deg);
        }
  75%  {
        opacity: 1;
        top: -20px;
        box-shadow: var(--shadow-75);
        transform:translate(-50%, -50%) rotate(90deg);
        }
  100% {
        opacity: 0;
        top: 10px;
        box-shadow: var(--shadow-15);
        transform:translate(-50%, -50%) rotate(90deg);  
        }
}