
.menuItem {
    text-decoration: none;
    height: 75px;
    flex: 0 1 165px;
    /* max-width: 175px; */
    color: var(--color-gray-35);
    fill: var(--color-gray-35);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .25s;
    border: none;
    border-bottom: 3px solid white;
    background-color: white;
    font-size: var(--font-size-body);
    cursor: pointer;
    font-weight: bold;
    font-family: 'M PLUS Rounded 1c', sans-serif;

}

@media screen and (max-width: 800px) {
    .label {
        display: none;
    }

    .menuItem {
        flex: 0 1 75px;
    }
}


/* Reward */
.reward:hover {
    background-color: var(--color-reward-5);
    border-bottom: 3px solid var(--color-reward-25);
    color: var(--color-gray-75);
    fill: var(--color-reward-75);
}

/* .active, .menuItem:hover {
    color: white;
    fill: white;
} */

.reward.active {
    background-color: var(--color-reward-5);
    border-bottom: 3px solid var(--color-reward-25);
    color: var(--color-gray-75);
    fill: var(--color-reward-75);
}


/* Store */
.store:hover {
    background-color: var(--color-store-5);
    border-bottom: 3px solid var(--color-store-25);
    color: var(--color-gray-75);
    fill: var(--color-store-75);
}

.store.active {
    background-color: var(--color-store-5);
    border-bottom: 3px solid var(--color-store-25);
    color: var(--color-gray-75);
    fill: var(--color-store-75);
}

/* SEL */
.sel:hover {
    background-color: var(--color-sel-5);
    border-bottom: 3px solid var(--color-sel-25);
    color: var(--color-gray-75);
    fill: var(--color-sel-75);
}

.sel.active {
    background-color: var(--color-sel-5);
    border-bottom: 3px solid var(--color-sel-25);
    color: var(--color-gray-75);
    fill: var(--color-sel-75);
}