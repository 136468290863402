.buttonContainer {
    display: flex;
    justify-content: flex-end;
}

.container {
    padding: 20px;
}

/* Radio Button Styling */
.radio:has(input:checked) input {
    background-color: white;
}

.radio:has(input:checked) {
    background-color: var(--color-sel-5);
    color: var(--color-gray-75);
}

.radio:has(input:checked).angry {
    fill: var(--color-emotion-angry)
}

.radio:has(input:checked).calm {
    fill: var(--color-emotion-calm)
}

.radio:has(input:checked).stressed {
    fill: var(--color-emotion-stressed)
}