.item {
    cursor: pointer;
    background-color: white;
    font-size: 12px;
    text-align: left;
    color: var(--color-gray-75);
    display: flex;
    align-items: center;
    border: none;
    padding: 0px;
    margin: 0px;
    line-height: 1;
    width: 100%;
    border-radius: 5px;
}



.item span {
    justify-content: center;
    align-items: center;
    display: flex;

}

.item span svg {
    fill: var(--color-gray-35);
    width: 14px;
    height: 14px;
}

.item:hover {
    color: var(--color-gray-95);
    background-color: var(--color-gray-3);
    
}

.item:hover svg {
    fill: var(--color-gray-50);
}
