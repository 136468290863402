.mainHeader {
    background-color: white;
    display: flex;
    align-items: center;
    height: 75px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    box-shadow: var(--shadow-50);
    /* position: relative; */
    position: sticky;
}

.mainNav {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0;
    margin-left: 150px;
    width: 100%;
    
}

@media screen and (max-width: 1450px) {
    .mainNav {
        margin-left: 50px;
    }
  }

/* Logo */
.mobileLogo {
    display: none;
}

@media screen and (max-width: 800px) {
    .desktopLogo {
        display: none;
    }

    .mobileLogo {
        display: block;
        
        width: 35px;
        height: 35px;
        margin: 10px;
        margin-left: 20px;
        margin-top: 15px;
    }

    .mainNav {
        margin-left: 20px;
    }

}

@media screen and (max-width: 500px) {
    .mobileLogo {
        display: none;
    }
    
}

/* Mobile User Type Drawer */
.mobileUserTypeMenu {
    display: none;
}

@media screen and (max-width: 800px) {
    .mobileUserTypeMenu {
        fill: var(--color-blue-50);
        display: block;
        margin-left: 20px;
    }
}

@media screen and (max-width: 500px) {
    .mobileUserTypeMenu {
        margin-left: 0px;
        margin-right: -20px;
    }
    
}

.button {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 58px;
    height: 58px;
    padding: 0;
    border: 4px solid white;
    border-radius: 100%;
    box-shadow: var(--shadow-25);
}

.button img {
    margin-top: 4px;
}

.button:hover {
    background-color: transparent;
    border-color: transparent;
    box-shadow: var(--shadow-50);
}

.iconButton {
    box-shadow: none;
}

.iconButton:hover {
    
    border: 4px solid var(--color-blue-10); 
    background-color: var(--color-blue-10);
    box-shadow: none;
}

.teacherProfile {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    max-width: 50px;
}

/* Drawer */

.drawerContainer {
    width: 400px;
    height: 100vh;
    padding: 55px;
    position: fixed;
    top: 73px;
    right: 0px;
    background-color: white;
    z-index: 80;
    box-shadow: var(--shadow-50);
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.drawerLeft {
    left: 0px;
    padding: 0px;
}
