
body:has(.container), html:has(.container) {  background-color: white; }

header.section {
    margin-bottom: 40px;
    position: relative;
    z-index: var(--z-index-menu);
}




/* General Layout */
.section {
    width: 100vw;
    padding: 20px;
    position: relative;
    z-index: 2;
}

/* #yourworkitoutapp {
    margin-top: 50px;
} */

#sectionalltogethernow {
    z-index: 1;
    /* margin-top: 100px; */
}

#sectionplaytent {
    z-index: 0;
    padding: 0px;
}

.section.hidden {
    overflow: hidden;
}

.section.overlap {
    margin-top: -60px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px; 
    position: relative;
}

.container .profileImg {
max-width: 125px;
height: 125px;
width: 125px;
position: absolute;
z-index: 10;

}

.container .profileImg.one {
    top: -55px;
    left: 205px;
    animation: profileAnimation 2s linear 1s infinite alternate;
}

.container .profileImg.two {
    top: 0;
    left: -150px;
    animation: profileAnimation2 2s linear 1s infinite alternate;
}

.container .profileImg.three {
    top: 122px;
    left: -100px;
    animation: profileAnimation 2s linear 1s infinite alternate;
}

.container .profileImg.four {
    top: 250px;
    left: -150px;
    animation: profileAnimation2 2s linear 1s infinite alternate;
}

@media only screen and (max-width: 900px) {
    .container .profileImg {
        display: none;
    }

    .hide {
        display: none;
    }

    .noPadding {
        padding: 0px;
    }
}


.small {
    max-width: 800px;
}

.medium {
    max-width: 900px;
}

.center {
    text-align: center;
    justify-content: center;
    align-items: center;
}

.center p.columns {
    text-align: left;
    columns: 2;
}

@media only screen and (max-width: 900px) {
    .center p.columns {
        columns: 1;
    }
}

.flex {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.flexDirection {
    flex-direction: column;
    gap: 15px;
}

.flex300 {
    flex: 0 1 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex300 img {
    max-width: 250px;
    margin-top: -35px;
}

.flex900 {
    flex: 1 1 500px;
    display: flex;
    flex-direction: column;
    padding-top: 25px;
}

@media only screen and (max-width: 900px) {
    .flex900 p, .flex900 h2 {
        text-align: center;
    }

    .flex900 {
        align-items: center;
    }

    .medium {
        justify-content: center;
    }

    .howSection {
        padding: 0px;
    }

}



.grid {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
    gap: 25px;
    justify-content: center;
    width: 100%;
}

/* Imgs */

img {
    max-width: 100%;
}

img.curveImg {
    max-width: 100%;
    width: 100%;
    left: 0px;
    position: absolute;
    top: 100px;
}

@media only screen and (max-width: 1100px) {
    #sectionplaytent img.curveImg {
        display: none;
    }
}

.logoAcronym {
    max-width: 180px;
    margin-bottom: 20px;
}

.whoFor {
    position: relative;
    z-index: 90;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-family: cerab, san-serif;
    font-weight: normal;
    margin-top: -50px;
}

.whoFor li {
    background-color: white;
    border-radius: 50px;
    height: 65px;
    flex: 0 1 215px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-blue-75);
    border: 8px solid white;
}

.whoFor li:nth-child(1) {
    background-color: var(--color-orange-2);
}

.whoFor li:nth-child(2) {
    background-color: var(--color-green-2);
}

.whoFor li:nth-child(3) {
    background-color: var(--color-ltblue-2);
}

.whoFor li:nth-child(4) {
    background-color: var(--color-blue-2);
}

#sectionplaytent img.kidImg {
    margin-top: -100px;
}

#sectionplaytent img.curveImg {
    top: -305px;
}

#sectionalltogethernow img.curveImg {
    top: -465px;
}

#sectionalltogethernow h2 span {
    font-family: cera, san-serif;
    font-weight: bold;
}

/* This is to keep image above background elements such as the wave */
.container img, .container a, .container h2 {
    position: relative;
    z-index: 1;
}

.circleBack {
    background-color: black;
    width: 375px;
    height: 375px;
    position: absolute;
    border-radius: 100%;
    opacity: .07;
    
}

@media only screen and (max-width: 900px) {
    .circleBack {
        display: none;
    }
}

.circleBack.ltblue {
    background-color: var(--color-ltblue-50);
    left: -275px;
    bottom: -125px;
    animation: circleAnimation 7s linear 2s infinite alternate;
}


.circleBack.orange {
    background-color: var(--color-orange-50);
    left: -150px;
    bottom: -230px;
    animation: circleAnimation2 7s linear 2s infinite alternate;
}

.circleBack.green {
    background-color: var(--color-green-50);
    bottom: -219px;
    left: 65px;
    animation: circleAnimation2 8s linear 2s infinite alternate;
}

.circleBack.blue {
    background-color: var(--color-blue-50);
    left: 250px;
    bottom: -300px;
    animation: circleAnimation 6s linear 2s infinite alternate;
}

@keyframes circleAnimation {
    from {transform: translate(0px, 0px);}
    to {transform: translate(20px, 40px);}
}

@keyframes circleAnimation2 {
    from {transform: translate(0px, 0px);}
    to {transform: translate(40px, 110px);}
}

.circleIcon {
    border: 8px solid var(--color-blue-10);
    width: 125px;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.circleIcon img {
    width: 65px;
}

.circleIcon svg {
    fill: white;
}

.circleIcon.green {
    background-color: var(--color-green-50);
    border-color: var(--color-green-5);
}

.circleIcon.orange {
    background-color: var(--color-orange-50);
    border-color: var(--color-orange-5);
}

.circleIcon.ltblue {
    background-color: var(--color-ltblue-50);
    border-color: var(--color-ltblue-5);
}

/* Nav */

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
}

.nav a {
    text-decoration: none;
    color: var(--color-gray-75);
}

.nav div {
    display: flex;
    flex: 0 1 auto;
    gap: 25px;
    align-items: center;
}

nav.mobile {
    display: none;
    position: relative;
    z-index: var(--z-index-menu);
}

nav.mobile .mobileMenu {
    width: 100%;
    position:absolute;
    top: 60px;
    left: 0;
    background-color: white;
    height: 100vh;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px 20px;
    z-index: var(--z-index-menu);
}

.menuIcon {
    position: relative;
    z-index: cal(var(--z-index-menu) + 1);
}

@media only screen and (max-width: 900px) {
    nav.desktop {
        display: none;
    }

    nav.mobile {
        display: flex;
    }


    nav.mobile summary::marker {
        display: none;
    }

    details summary::-webkit-details-marker {
        display:none;
      }

      details > summary {
        list-style: none;
      }
}

/* Buttons */

a.button {
    padding: 10px 15px;
    border-radius: var(--border-radius-75);
    background-color: var(--color-blue-50);
    text-decoration: none;
    color: white;
    font-weight: bold;
    border: 3px solid white;
    display: block;
    max-width: 165px;
    text-align: center;
}

a.button.centerblock {
    margin: 25px auto;
}

a.button.large {
    padding: 12px 17px;
    font-size: 22px;
    border-radius: 45px;
    /* margin-top: 25px; */
}

a.button.center {
    position: absolute;
    top: -20px;
    z-index: 10;
    left: 50%;
    transform: translateX( -50%);
    margin: 0px;
}

a.button:hover {
    background-color: white;
    color: var(--color-blue-50);
    border-color: var(--color-blue-50);
}

a.button.green {
    background-color: var(--color-green-50);
    
}

a.button.green:hover {
    color: var(--color-green-50);
    background-color: white;
    border-color: var(--color-green-50);
    
}

a.button.orange {
    background-color: var(--color-orange-50);
    
}

a.button.orange:hover {
    color: var(--color-orange-50);
    background-color: white;
    border-color: var(--color-orange-50);
}

a.button.ltblue {
    background-color: var(--color-ltblue-50);
    
}

a.button.ltblue:hover {
    color: var(--color-ltblue-50);
    background-color: white;
    border-color: var(--color-ltblue-50);
}

/* Header */
header.section {
    background-color: white;
}

/* Main Section */

/* Typography */



.container p {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 20px;
}

.container h2 {
    font-family: cera, san-serif;
    font-size: 60px;
    margin-bottom: 15px;
    color: var(--color-blue-50);
    margin-top: 0px;
}

.container h2 span {
    font-family: cerab, san-serif;
    font-weight: normal;
}

.green {
    color: var(--color-green-50);
}

.orange {
    color: var(--color-orange-50);
}

.ltblue {
    color: var(--color-ltblue-50);
}

.container .subheader {
    font-size: 30px;
    color: var(--color-gray-50);
    }
    
    .container .mainheader {
        font-size: 90px;
        margin-top: -10px;
        margin-bottom: 0px;
    }

/* Animated Wave */
.animatedWave {
    position:absolute;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    opacity: .2;
    z-index: 0;
  }
  
  .animatedWave path {
  
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-name: large-wave-move;
    animation-timing-function: linear;
    transition: linear;
    transition-timing-function: linear;
  }
  
  @keyframes large-wave-move {
    0% {transform: translateX(-100%)}
    to {transform: translateX(0)}
  }


/* Footer */
footer.section {
    background-color: var(--color-blue-50);
}

footer.section a {
    color: white;
}

footer.section a.button.buttonWhite {
    background-color: white;
    border-color: white;
    color: var(--color-blue-50);
}

footer.section a.button.buttonWhite:hover {
    background-color: var(--color-blue-50);
    color: white;
}

.signature {
    text-align: center;
    color: white;
    margin-bottom: 10px;
    margin-top: 40px;
    opacity: .5;
}


/* Flex Flip. */

.containerFlip {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    clear: both;
    display: flex;
    flex-wrap: wrap;
}

.containerFlip img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
}

.containerFlip h2 {
    font-size: 40px;
}

.flexOneThird {
    text-align: center;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 500px;
    
    position: relative;
}

.flexTwoThirds {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 500px;
    padding: 40px;
}

.flexOneSwitch {
    order: 2;
}

.flexTwoThirdsSwitch {
    order: 1;
}

@media only screen and (max-width: 1100px) {
    .containerFlip {
        text-align: center;
    }

.flexTwoThirds {
    flex: 1 1 auto;
    align-items: center;
}

.flexTwoThirdsSwitch {
    order: 2;
}
}



.container .containerFlip:nth-child(1) {
    background-color: var(--color-ltblue-2);
}

.container .containerFlip:nth-child(2) {
    background-color: var(--color-green-2);
}

.container .containerFlip:nth-child(3) {
    background-color: var(--color-orange-2);
}

.container .containerFlip:nth-child(4) {
    background-color: var(--color-blue-2);
}
