.container {
    background-color: transparent;
    width: 900px;
    gap: 40px;
    z-index: 100000;
    border: 0px;
}

dialog.container::backdrop {
    background: #1540a3ad;
    backdrop-filter: blur(1px);   
}

.frame {
    width: 800px;
    height: 500px;
    border: none;
}

.closeIcon {
    position: relative;
    z-index: calc(var(--z-index-modal) + 1);
}

.closeIcon svg {
    fill: white;
}

.closeIcon svg:hover {
    fill: var(--color-blue-75);
}

.header, .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px -50px;
}

.footer {
    margin: 20px;
}

.center {
    justify-content: center;
}

.header h2 {
    margin: 0px;
}

.header button {
    background-color: transparent;
    outline: 0px;
    border: 0px;
    fill: var(--color-gray-35);
    cursor: pointer;
    border-radius: 100%;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header button:hover {
    fill: var(--color-gray-50);
    background-color: var(--color-gray-5);

}

.body {
    margin: 0 20px 20px;
}