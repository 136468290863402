
.container img {
    object-fit: cover;
    object-position: center;
    border: 1px solid var(--color-gray-15);
}

/* Sizes */
.tiny {
    width: 40px;
    height: 40px;
    max-width: 40px;
}

.small {
    width: 57px;
    height: 57px;
    max-width: 57px;
}

.medium {
    width: 150px;
    height: 150px;
}

.full {
    width: 100%;
}

/* Type */
.container .profile {
    border-radius: var(--border-radius-100);
    border: 4px solid white;
    /* box-shadow: var(--shadow-50); */
}

.item {
    border-radius: var(--border-radius-5);

}

/* Loading */

.loading {  
    animation: loadinganimation 2s ease-in-out infinite;
    
}

@keyframes loadinganimation {
    0%{ background-color: var(--color-gray-5); }
    50%{ background-color: var(--color-gray-15); }
    100%{ background-color: var(--color-gray-5); }
}