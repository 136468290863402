.fullLogo, .fullWhiteLogo {
    flex: 0 1 165px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
}

.fullLogo svg, .fullWhiteLogo svg {
    height: 40px;
}

.medium svg {
    height: 50px;
}

.large svg {
    height: 100px;
}

.iconOnly {
    max-width: 100px;
}

/* Blue Logo */
.color1 {
    fill: var(--color-blue-50);
}

.color2 {
    fill: var(--color-green-50);
}

.color3 {
    fill: var(--color-orange-50);
}

.color4 {
    fill: var(--color-ltblue-50);
}

/* White Logo */

.color11 {
    fill: white;
}

.color22 {
    fill: var(--color-green-5);
}

.color33 {
    fill: var(--color-orange-5);
}

.color44 {
    fill: var(--color-ltblue-5);
}