
.section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
    gap: 10px;
}

.status {
    display: flex;
    align-items: center;
    justify-content: center; 
    
}

.list .status {
    flex: 0 0 180px;
    justify-content: start;
}

.comments {
    text-align: left;
    display: flex;
    flex: 1 1 230px;
    gap: 10px;
}

.sectionOne {
    flex: 1 1 250px;
}

.date {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 10px;
    margin-right: 15px;
}

.name {
    display: block;
    padding-top: 5px;
}

.calm {
    fill: var(--color-emotion-calm);
}

.stressed {
    fill: var(--color-emotion-stressed);
}

.angry {
    fill: var(--color-emotion-angry);
}

.nocheck {
    fill: var(--color-gray-25);
}