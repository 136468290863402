.workplaceMock {
    max-width: 100%;
    padding: 25px;
}

.container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.post {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-bottom: 30px solid var(--color-gray-5);   
}

.imagePost {
    width: 100%;
    background-color: var(--color-gray-15);
    background-size: cover;
    background-position: center center;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.post:last-child {
    border: none;
}

.header {
    padding: 15px;
    border-bottom: 1px solid var(--color-gray-15);
}

.header .left img {
    margin-right: 5px;
}

.header, .header .left {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.body {
    padding: 15px;
}

.body p {
    text-align: left;
    font-size: 13px;
    line-height: 1.6;

}


.fullWidth {
    flex: 1 0 100%;
    width: 100%;
}

/* Post Options */
article:hover > .options {
    opacity: 1;
}

.options {
    position: absolute;
    right: 5px;
    top: 9px;
    width: 20px;
    height: 20px;
    fill: var(--color-gray-25);
    z-index: 999;
    border: none;
    opacity: 0; 
    transition: all .5s;
}

.options svg {
    width: 18px;
    height: 18px;
}

.options:hover {
    background-color: white;
    fill: var(--color-gray-50);
    border: none;
}