
:root {
  /* Colors */
  /* Blue */
    --color-blue-2: #e4ebfb;
    --color-blue-5: #dee5f0;
    --color-blue-10: #d0dbf8;
    --color-blue-15: #bbcaf0;
    --color-blue-25: #7d95d2;
    --color-blue-50: #1B57E0;
    --color-blue-75: #153FA3;

  /* Gray */
    --color-gray-95: #262729;
    --color-gray-75: #343333; 
    --color-gray-50: #808080;
    --color-gray-35: #a6a6a6;
    --color-gray-25: #bfbfbf;
    --color-gray-15: #d9d9d9;
    --color-gray-10: #e6e5e5;
    --color-gray-5: #ededed;
    --color-gray-3: #f5f5f4;
    --color-gray-2: #fafafa;

    /* Red */
    --color-red-5: #fde9e9;
    --color-red-50: #bc0006;

  /* Features */
  /* Reward */
  --color-reward-5: #FEF9F3;
  --color-reward-15: #feefdd;
  --color-reward-25: #FCD5A5;
  --color-reward-50: #f9b664;
  --color-reward-75: #ea8d01;
  --color-reward-100: #cb6900;

  /* Store */
  --color-store-5: #F6F8FB;
  --color-store-15: #dbe4fb;
  --color-store-25: #c0cff6;
  --color-store-50: #5784ec;
  --color-store-75: #153FA3;

  /* SEL */
  --color-sel-5: #F9F4F9;
  --color-sel-15: #f9e8f9;
  --color-sel-25: #DBB8DA;
  --color-sel-50: #e970e7;
  --color-sel-75: #90278E;

  /* SEL Emotion */
  --color-emotion-calm: #1bb564;
  --color-emotion-angry: #c61a20;
  --color-emotion-stressed: #e78d1f;

  /* Brand */
  --color-orange-2: #F5EFE8;
  --color-orange-5: #FFC96B;
  --color-orange-50: #F28E1E;

  --color-green-2: #EBF2E6;
  --color-green-5: #D0F780;
  --color-green-50: #88C23F;

  --color-ltblue-2: #E6EDF2;
  --color-ltblue-5: #80E0FF;
  --color-ltblue-50: #25A5DC;

  /* Border Radius */
  --border-radius-out: 9px;
  --border-radius-student: 12px;

  --border-radius-5: 9px;
  --border-radius-15: 12px;
  --border-radius-75: 20px;
  --border-radius-100: 100%;

  /* Z Index */
  --z-index-modal: 9000;
  --z-index-message: 8000;
  --z-index-menu: 7000;
  --z-index-header: 5000;
  --z-index-content: 3000;
  --z-index-footer: 1000;
  
  /* Shadow */
  --shadow-15: 0px 1px 2px #00000010, 0px 0px 4px #153FA310;
  --shadow-25: 0px 1px 2px #00000010, 0px 0px 6px #1540a32f;
  --shadow-50: 0px 1px 3px #00000030, 0px 0px 9px #153FA330;
  --shadow-75: 0px 2px 4px #00000050, 0px 0px 9px #153FA330;
  --shadow-100: 0px 3px 5px #00000055, 0px 0px 9px #1540a34f;

  /* Type Size */
  --font-size-title-1: 18px;
  --font-size-body: 16px;
  --font-size-details: 12px;

  /* Animation */
   --time-50: .7s; /* transition */
}





@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');
@font-face {
  font-family: Cera;
  src: url(../public/font/CeraRoundProBlack.otf);
}

@font-face {
  font-family: CeraB;
  src: url(../public/font/CeraRoundProRegular.otf);
}


* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-body);
  outline: none;
  font-family: 'Inter', sans-serif;
  font-style: 500;
  color: #333;
  font-size: 16px;
  background-color: var(--color-blue-2);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-blue-2);
  
}

/* Typography */
h1,
p,
label {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  line-height: 1;
  font-family: inherit;
  vertical-align: baseline;
  *vertical-align: middle;
  line-height: normal;
  *overflow: visible;
  font-family: 'Inter', sans-serif;
  font-size: var(--font-size-body);

}

h1 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}

p {
  color: var(--color-gray-75);
  line-height: 1.4;
}

label {
  text-align: left;
}

strong {
  color: var(--color-gray-95);
}

a {
  color: var(--color-blue-75);
}

a:hover {
  text-decoration: none;
}

.details {
  font-size: var(--font-size-details);
  color: var(--color-gray-50);
  display: block;
}

/* Table */
table {
  background-color: white;
  padding: 16px;
  width: 100%;
  text-align: left;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

table thead {
padding: 12px 8px;
margin: -12px;
background: var(--color-gray-3);
border-radius: 6px 6px 0 0;
margin-bottom: 6px;
}

table tr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

}

table tbody tr {
  margin: 0 -12px;
  padding: 0 12px;
}


table tr:nth-child(even) {
  background-color: var(--color-gray-2);
}

table tr td, table tr th {
  flex: 0 1 75px;
  display: flex;
  align-items: center;
}

table tr .action {
  flex: 0 0 15px;
}

table tr .main {
  flex: 1 1 200px;
}

table tr .name {
  flex: 0 0 100px;
}

table tr .data {
  flex: 0 0 20px;
  display: flex;
  justify-content: center;
  text-align: center;
}

table img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 4px;
  margin-left: 12px;
}

.tableCenter {
  text-align: center;
  
}

/* Form Stuff */

.formContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 0 100%;
  margin-bottom: 15px;
}

.panelForm {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  text-align: left;
}

/* input[type='file'] span, input[type='file'] label, input[type='file'] p {
  display: none !important;
} */

input[type='file'] {
  background-color: transparent;
  height: 100px;
  color: transparent;
}

/* buttons */
input[type=file]::file-selector-button {
  background-color: white;
  min-width: 125px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #9cb8f9;
  border-radius: 25px;
  color: var(--color-gray-75);
  margin-bottom: 20px;
  cursor: pointer;
  font-size: var(--font-size-body);
  font-weight: bold;
  margin-bottom: 0px;
  background-color: var(--color-blue-50);
  color: white;
}

input[type=file]::file-selector-button {
  margin-right: 10px;
}

input[type=file]::file-selector-button:hover {
  background-color: white;
  color: var(--color-blue-50);
}

input[type=file]::file-selector {
  display: none;
}

input, textarea, select {
  height: 55px;
  border-radius: var(--border-radius-75);
  font-family: 'Inter', sans-serif;
  background-color: var(--color-gray-5);
  border: none;
   /* border: 2px solid var(--color-gray-35); */
  border: 2px solid white;
  margin-top: 5px;
  padding: 15px;
  transition: .2s all;
  font-size: var(--font-size-body);
}



input:focus, textarea:focus {
  border: 2px solid var(--color-gray-15);
  outline: var(--color-gray-15);
  background-color: var(--color-gray-2);
}

textarea {
  height: 100px;
}



.radioButton {
  display: flex;
  align-items: center;
  height: 0;
  padding: 25px 0;
  margin-left: 10px;
  background-color: var(--color-gray-3);
  fill: var(--color-gray-3);
  padding: 25px 10px;
  margin: 5px 0;
  border-radius: 7px;
  color: var(--color-gray-35);
  font-weight: bold;
  cursor: pointer;
  position: relative;
}

.radioButtonCheck {
  fill: var(--color-blue-50);
  position: absolute;
  left: 18px;
  top: 15px;
  display: none;
}

.radioButton:has(input:checked) .radioButtonCheck {
  display: block;
}

.radioButton:has(input:checked) {
  color: var(--color-gray-95); 
}

.radioButton:has(input:checked) input {
  background-color: white;
}



.radioButton input {
  margin-right: 10px;
  border: none;
  appearance: none;
  background-color: var(--color-gray-15);
  
  border-radius: 100%;
  width: 25px;
  height: 25px;
  margin-top: 0px;
  cursor: pointer;
}

.error {
  text-align: right;
  font-size: 12px;
  margin-top: 5px;
  padding: 3px 6px;
  border-radius: 10px;
  color: #cf0303;
  background-color: #fae6e6;
  max-width: auto;
  position: absolute;
  top: -2px;
  right: 12px;
}

.animated-wave {
  position:absolute;
  bottom: -50px;
  width: 100vw;
  opacity: .2;
}

.animated-wave path {

  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-name: large-wave-move;
  animation-timing-function: linear;
  transition: linear;
  transition-timing-function: linear;
}

@keyframes large-wave-move {
  0% {transform: translateX(-100%)}
  to {transform: translateX(0)}
}

