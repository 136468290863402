.containercenter {
    display: flex;
    align-items: center;
}

.userName {
    position: absolute;
    top: 60px;
}

.buttonImageContainer {
    display: flex;
    justify-content: center;
}

/* Image Preview */
.imagePreview {
    margin-top: 25px;
}

.imagePreview img {
    max-width: 200px;
    height: 200px;
    border-radius: 100%;
    margin: 0 auto;
}

.image {
    width: 200px;
    height: 200px;
    background-size: cover;
    border-radius: 100%;
    margin: 0 auto;
    background-repeat: none;
}

/* File Input */
.inputFile {
    display: none;
}

.imageButton {
    margin-bottom: 20px;
    min-width: 150px;
}

.imageButton label {
    font-weight: bold;
}

.imageLabelButton {
    cursor: pointer;
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}



