.grid {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
    gap: 15px;
}

.cardLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -10px;
}

.reward {
    fill: var(--color-reward-50);
}

.button {
    background-color: white;
    min-width: 110px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #9cb8f9;
    border-radius: 19px;
    color: var(--color-gray-75);
    margin-bottom: 20px;
}

.button:hover {
    border-color: var(--color-blue-25);
    box-shadow: var(--shadow-15);
}

.calm {
 fill: var(--color-emotion-calm);
}

.stressed {
    fill: var(--color-emotion-stressed);
}

.angry {
    fill: var(--color-emotion-angry);
}

.studentCheckBox {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}