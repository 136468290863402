.containercenter {
    display: flex;
    align-items: center;
}

.imagePreview {
    width: 100%;
    background-color: var(--color-gray-15);
    background-size: cover;
    background-position: center center;
    height: 200px;
    border-radius: var(--border-radius-75);
}

.inputFile {
    display: none;
}

.buttonImageContainer {
    position: absolute;
    right: 10px;
    bottom: -5px;
}

.imageButton {
    margin-bottom: 20px;
    min-width: 150px;
}

.imageButton label {
    font-weight: bold;
}

.imageLabelButton {
    cursor: pointer;
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
