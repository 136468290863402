.container {
  position: relative;
}

.card {
    background-color: white;
    border-radius: var(--border-radius-out);
    box-shadow: var(--shadow-50);
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}

.student {
display: flex;
flex-direction: column;
padding: 17px 10px;
margin: 0px;
transition: all .2s;
cursor: pointer;
border-radius: var(--border-radius-student);
border: 6px solid white;
outline: 1px solid white;
}

.student p strong {
  font-size: 14px;
}

.panel {
  position: sticky;
  top: 125px;
}

.student:hover {
    box-shadow: var(--shadow-75);
}

.student img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin-bottom: 7px;
}   

.student.selected {
    border: 6px solid gray;
    box-shadow: var(--shadow-75);
  }

  .student.selected:hover {
    box-shadow: var(--shadow-100);
}

.student.selected.reward {
    border-color: var(--color-reward-25);
    outline-color: var(--color-reward-50);
  }

  .student.selected.store {
    border-color: var(--color-store-25);
    outline-color: var(--color-store-50);
  }

  .student.selected.sel {
    border-color: var(--color-sel-25);
    outline-color: var(--color-sel-50);
  }



  .studentCheckBox {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.iconCircle {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-reward-75);
  border: 3px solid white;
  fill: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  border-radius: 100%;
  opacity: 0;
  animation-name: reward;
  animation-duration: 2s;
  box-shadow: var(--shadow-15);
}

.iconCircle svg {
  margin-left: 0px;
  margin-top: -2px;
}

@keyframes reward {
  0%   {
          opacity: 0;
          top: 10px;
          box-shadow: var(--shadow-15);
          transform:translate(-50%, -50%) rotate(0deg);
        }
  75%  {
        opacity: 1;
        top: -20px;
        box-shadow: var(--shadow-75);
        transform:translate(-50%, -50%) rotate(90deg);
        }
  100% {
        opacity: 0;
        top: 10px;
        box-shadow: var(--shadow-15);
        transform:translate(-50%, -50%) rotate(90deg);  
        }
}